import React, { useContext } from "react";
import Slider from "react-slick";
import { SectionTitle } from "..";
import { AppContext } from "../../context";
import "../Testimonial/testimonial.scss";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
function Index() {
  const { dataList } = useContext(AppContext);
  const data = dataList?.gnanam_homes_testimonials;
  return (
    <section className="section">
      <div className="container">
        <div className="text-center">
          <h4 className="testmonial-title">TESTIMONIAL</h4>
          <SectionTitle
            title="PEOPLE SAY"
            highLightText="WHAT"
            content={''}
          />
        </div>
        <div data-aos="fade-up" data-aos-delay="200">
          {
            data ?
              <Slider {...settings} >
                {data.data.map((item) => (
                  <div className="p-1 mb-3 " key={item._id}>
                    <div className="testimonial-card shadow border rounded">
                      <p>{item.content}</p>
                      <div className="d-flex align-items-center">
                        <img
                          src={item.image.url}
                          className="testimonial-image"
                          alt="testimonial-image"
                        />
                        <div className="mx-3 text-primary">
                          <h6 className="m-0 fw-bold"> {item.name} </h6>
                          <p className="m-0"> Customer </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              : null
          }
        </div>
      </div>
    </section>
  );
}

export default Index;