import React from 'react'
import './index.scss'
function index({ title, highLightText, content }) {
    return (
        <center data-aos="fade-up">
            <h1 className='section_title'><span>{highLightText}</span> {title}</h1>
            <p className='section_content'>{content}</p>
        </center>
    )
}

export default index