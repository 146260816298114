import {
    QueryClient,
    QueryClientProvider
} from '@tanstack/react-query';
import React, { useEffect } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Outlet } from 'react-router-dom';
import { Footer, Header } from './components';

const queryClient = new QueryClient()

const Layout = () => {
    useEffect(() => {
        window.scroll(0, 0)
    },)
    return (
        <QueryClientProvider client={queryClient}>
            <Header />
            <Outlet />
            <Footer />
        </QueryClientProvider>
    )
}

export default Layout