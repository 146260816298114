import React, { useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { SectionTitle } from '..';
import { AppContext } from '../../context';
import './index.scss';
const Index = () => {
    const { dataList } = useContext(AppContext);
    const data = dataList?.gnanam_homes_blogs;
    return (
        <section className='section py-5' id='blogs'>
            <div className="container">
                <SectionTitle title={'Latest Articles'} highLightText={'OUR'} content={"Discover our latest articles where we delve deep into real experiences and insights. Each blog post offers authentic narratives drawn from our personal journeys. Dive in to explore compelling stories that resonate with genuine emotions and lessons learned. Engage with our content to enrich your understanding and connect with shared human experiences. Don't miss out on these captivating tales from our lived experiences. Read, reflect, and relate with us today!"} />
                <div className="row mt-4 x-center">
                    {
                        data ?
                            data.data.map((item) => (
                                <div key={item._id} className="col-lg-4 col-md-6 p-3" data-aos="fade-up" data-aos-delay="200">
                                    <LazyLoadImage width={"100%"} effect="blur" className='w-100 blog-image' src={item.image.url} />
                                    <div className="blog-card-content">
                                        <div className="blog-title">{item.title}</div>
                                        <div className="blog-content">{item.description.substring(0, 70)}...</div>
                                        <Link to={`/blogs/${item.slug}`} className="blog-link">READ MORE</Link>
                                    </div>
                                </div>
                            ))
                            : null
                    }

                </div>
            </div>
        </section>
    )
}

export default Index