import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SectionTitle } from '..';
import CategoryFilter from './CategoryFilter';
import ServiceCards from './ServiceCards';
import './index.scss';

const Index = () => {
    const { id } = useParams()
    const [currentCategory, setCurrentCategory] = useState(id || 'all');
    const [filteredData, setFilteredData] = useState([]);
    const [loader, setLoader] = useState("");

    const handleTabChange = (categoryId) => {
        setLoader(categoryId)
        setCurrentCategory(categoryId)
        const filters = {}
        if (categoryId !== 'all') {
            filters['id'] = 'service_id';
            filters['value'] = categoryId;
        }
        axios.post(`https://ej-projects.vercel.app/api/web`, {
            moduleName: "gnanam_homes_services",
            limit: 50,
            filters: Object.keys(filters).length ? [filters] : []
        }).then(res => {
            setFilteredData(res.data.data)
        }).finally(() => {
            setLoader("")
        })
    };

    useEffect(() => {
        handleTabChange(id || 'all')
    }, [id])
    return (
        <section className='section' id='services'>
            <div className='container'>
                <SectionTitle
                    title="Services"
                    highLightText='our'
                    content="Discover unparalleled property solutions with Chennai's best real estate company. We are the trusted choice for discerning clients seeking top-tier residential and commercial properties in Chennai. As the premier Chennai best property sellers, we offer expert guidance, tailored strategies, and a vast portfolio of prime real estate options. Whether you're buying, selling, or investing, our dedicated team ensures a seamless and rewarding experience every step of the way. Choose excellence; choose us for all your property needs."
                />
            </div>
            <CategoryFilter loader={loader} categoryId={currentCategory} handleClick={handleTabChange} />
            {filteredData ? <ServiceCards data={filteredData} /> : null}
        </section>
    )
}

export default Index