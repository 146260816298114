import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getServiceCategories } from '../../services';

function CategoryFilter({ handleClick, categoryId, loader }) {

    const { isSuccess, data } = useQuery({ queryKey: ['servicesCategories'], queryFn: getServiceCategories })

    return (
        <div className='d-flex flex-wrap gap-3 x-center my-5' data-aos="fade-up" data-aos-delay="200">
            <button className={`btn-filter ${categoryId === 'all' ? "active" : ''}`} onClick={() => handleClick('all')}>
                <span>
                    {loader === 'all' ? <div class="spinner-border" role="status"></div> : 'All'}
                </span>
            </button>
            {
                isSuccess ?
                    data.data.data.map((item) => (
                        <button className={`btn-filter ${categoryId === item._id ? "active" : ''}`} key={item._id} onClick={() => handleClick(item._id)}>
                            <img height={46} src={item.image.url} />
                            <span>
                                {loader === item._id ? <div class="spinner-border" role="status"></div> : item.title}
                            </span>
                        </button>
                    ))
                    : null
            }
        </div>
    )
}

export default CategoryFilter