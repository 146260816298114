import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { getServiceCategories } from '../../services';

const ServiceDropDown = () => {
    const { data, isSuccess } = useQuery({
        queryKey: ['servicesCategories'], queryFn: getServiceCategories
    });
    return (
        <Dropdown className='d-inline' as={ButtonGroup}>
            <Button className='navbar-link' as={NavLink} to={'/services'} style={{ marginTop: -6 }}>
                Services
                <Dropdown.Toggle className='btn-unstyle' split />
            </Button>


            <Dropdown.Menu>
                {
                    isSuccess ?
                        data.data.data.map((item, i) => (
                            <Dropdown.Item key={i} className='d-block' as={Link} to={`/services/${item.slug}/${item._id}`}>
                                {item.title}
                            </Dropdown.Item>
                        ))
                        : null
                }
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ServiceDropDown;
