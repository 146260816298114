import axios from "axios";
import { baseUrl } from "../configs/requestsData";

export const getAllBlogs = async () => {
    return await axios.post(baseUrl, {
        moduleName: "gnanam_homes_blogs",
        limit: 14,
        sorting: [
            {
                desc: -1,
                id: "_id"
            }
        ]
    })
}

export const getServiceCategories = async () => {
    return await axios.post(baseUrl, {
        moduleName: "gnanam_homes_service_categories",
    })
}

export const getAllServices = async () => {
    return await axios.post(baseUrl, {
        moduleName: "gnanam_homes_services",
    })
}

export const getServiceBySlug = async (slug) => {
    return await axios.post(baseUrl, {
        moduleName: "gnanam_homes_services",
        filters: [
            {
                value: slug,
                id: "slug"
            }
        ]
    })
}

export const getBlogBySlug = async (slug) => {
    return await axios.post(baseUrl, {
        moduleName: "gnanam_homes_blogs",
        limit: 14,
        filters: [
            {
                value: slug,
                id: "slug"
            }
        ]
    })
}


export const getRelatedBlogs = async (slug) => {
    return await axios.post(baseUrl, {
        moduleName: "gnanam_homes_blogs",
        limit: 14,
        filters: [
            {
                value: slug.replaceAll('-', ' '),
                id: "slug"
            },
            {
                value: slug.replaceAll('-', ' '),
                id: "title"
            },
            {
                value: slug.replaceAll('-', ' '),
                id: "description"
            }
        ]
    })
}



