import React from "react";
import { AboutUs, Banner, LatestArticles, Services, Testimonial } from "../components";
import ApiProvider from "../providers/ApiProvider";

const Home = () => {
  return (
    <ApiProvider>
      <Banner />
      <Services />
      {/* <LatestWorks /> */}
      <AboutUs />
      <LatestArticles />
      <Testimonial />
    </ApiProvider>
  );
};

export default Home;
