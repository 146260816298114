import React, { useContext } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import Slider from "react-slick";
import { AppContext } from "../../context";
import "../Banner/banner.scss";

function Index() {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  const { dataList } = useContext(AppContext);
  const data = dataList?.gnanam_homes_banners;

  return (
    <section className="bg-primary banner-section">
      {
        data ?
          <Slider {...settings}>
            {data.data.map((item, i) => {
              return (
                <>
                  <div className="banner-container" style={{
                    backgroundImage: `linear-gradient(45deg, #000000d5, #ffffff00), url(${item.image.url})`
                  }}>
                    <div className="container">
                      <div data-aos="fade-up" key={i}>
                        <h3 className="banner-subTitle ">
                          <span className="highlight">{item.sub_title}</span>
                        </h3>
                        <h1 className="banner-title">
                          {item.title}
                        </h1>
                        {
                          item?.description ?
                            <p className="banner-content">{item?.description}</p>
                            : null
                        }
                        <a href='#contact-us' className="btn-primary-filled">
                          READ MORE
                          <span>
                            <FaLongArrowAltRight className="btn-arrow-icon" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
          : <div className="bg-primary" style={{ minHeight: '65vh' }}></div>
      }
      <div className="container text-center text-white">
        <div className="row justify-content-between x-center">
          <div data-aos="fade-up" data-aos-delay="200" className="col-lg-2 col-md-4 p-3 col-sm-6">
            <div className="info_count">02+</div>
            <div className="info_text">Years of experience</div>
          </div>
          <div data-aos="fade-up" data-aos-delay="600" className="col-lg-2 col-md-4 p-3 col-sm-6">
            <div className="info_count">07+</div>
            <div className="info_text">projects completed</div>
          </div>
          <div data-aos="fade-up" data-aos-delay="800" className="col-lg-2 col-md-6 p-3 col-sm-6">
            <div className="info_count">01+</div>
            <div className="info_text">ongoing projects </div>
          </div>
          <div data-aos="fade-up" data-aos-delay="1000" className="col-lg-2 col-md-6 p-3 col-sm-6">
            <div className="info_count">14+</div>
            <div className="info_text">Happy families</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
