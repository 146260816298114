import React, { useEffect, useState } from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { SectionTitle } from '..'
const Index = () => {
    const users = [
        {
            image: require('../../assets/images/Male.png'),
            name: 'Panchatcharam.R',
            designation: 'Managing Director',
            specialize: 'GE India.Civil Engineer with high rise buildings experience.',
            summary: `Panchatcharam is the Managing Director of Gnanam Engineering and Gnanam Homes and Estate in India. Panchatcharam has 20 years of experience, serving in several leadership and customer service roles. He was associated with L&T, BGR, Ramky, and ITD, both industrial and residential Property. Panchatcharam works on high-rise infrastructure projects, taking on end-to-end Responsibilities such as sales, business development, tendering, planning, and costing execution, he is member of BNI.`
        },
        {
            image: require('../../assets/images/female.png'),
            name: 'Gayathri Panchatcharam, B.Com, MBA',
            designation: 'Director & Finance Head',
            specialize: 'Finance & Accounts',
            summary: `Gayathri Panchatcharam is the Director and Finance Head for Gnanam Engineering and Gnanam Homes & Estate. Gayathri holds a B.Com and an MBA and works as a Director at GE India. She is an Accounts, Auditing, and Teaching Professional who has advised several significant multinational companies. She held the prominent role of the firm's Diversity and Inclusion Leader beginning in 2023 and implemented numerous improvements to make the company a better place to work.`,
        },
        {
            image: require('../../assets/images/Male02.png'),
            name: 'Karthikeyan',
            designation: 'Director',
            specialize: 'Real Estate Sector & Infrastructure',
            summary: 'Completed MBA With over 29 years of experience in the infrastructure & Realty sector, The landmark is acquired more than 3000 acres of lands in Tamilnadu,india and got approved and developed in phased manner with world class amenities like 18 hole Golf Course and Prime villas and as well handled very strongly in business development, marketing, and tendering. The author is well-versed in managing projects for many national and international projects. So far handled the volume of more than 100 Million worth of projects in Road, water and other infra related projects in India, Andaman Nicobar Island,Maldives and Uganda and Rwanda.',
        },
    ]
    const [userIndex, setUserIndex] = useState(0);
    const [user, setUser] = useState(users[0]);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                setUserIndex((prevIndex) => (prevIndex + 1) % users.length);
            }
        }, 2000);

        return () => clearInterval(interval);
    }, [isHovered]);

    useEffect(() => {
        setUser(users[userIndex]);
    }, [userIndex]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <section id="about-us">
            <div className="container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <SectionTitle title={'Us'} highLightText={'About'} content={"At Gnanam Homes & Estates in Chennai, excellence in real estate isn't just a goal—it's our standard. Led by our dynamic MD, our team, including our insightful Director and astute Director of Finance, collaborates seamlessly to offer unparalleled property solutions. With a blend of expertise and dedication, we are committed to turning your property aspirations into tangible realities. Trust Gnanam Homes & Estates for a superior and reliable real estate journey."} />
                <div className='d-flex flex-wrap gap-3 x-center my-5' data-aos="fade-up" data-aos-delay="200">
                    {
                        users.map(item => (
                            <button onClick={() => setUser(item)} className={`btn-filter ${user.name === item.name ? 'active' : ''}`} key={item.name}>
                                <span><b>GE</b> | {item.designation}</span>
                            </button>
                        ))
                    }
                </div>
                <div className="row y-center x-center" data-aos="fade-up" data-aos-delay="400">
                    <div className="col-md-6">
                        <h3 className='text-primary fw-bold m-0'> <span className='text-danger'>{user.name}</span> <span className='text-dark'>|</span>  {user.designation}</h3>
                        <div className='mb-5'><u>{user.specialize}</u></div>
                        <p>{user.summary}</p>
                        <a href='#contact-us' className="btn-primary-filled mt-3">
                            PROFILE | READ MORE
                            <FaLongArrowAltRight className="btn-arrow-icon" />
                        </a>
                    </div>
                    <div className="col-md-4 mt-3 mt-lg-0 px-0">
                        <LazyLoadImage effect="blur" src={user.image} width='100%' />
                    </div>
                </div>
            </div>
            <div className="bg-primary-gradient" data-aos="fade-up">
                <div className="container">
                    <div className="row text-white x-center y-center py-3">
                        <div className="col-md-4 text-lg-start text-center">
                            <p className='lead fw-bold mb-2'>GET YOUR FREE CONSULTANT</p>
                            <div className='small fw-light'>We are a company that offers design and build services for you from initial sketches to the final construction</div>
                        </div>
                        <div className="col-md-6 text-lg-end text-center">
                            <a href='#contact-us' className='btn btn-danger border-light border'>
                                CONTACT US
                                <FaLongArrowAltRight className="ms-2" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index