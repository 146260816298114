import React from 'react';
import Card from 'react-bootstrap/Card';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
const ServiceCards = ({ data }) => {
    return (
        <div className="container">
            <div className='row justify-content-center' data-aos="fade-up" data-aos-delay="400">
                {
                    data.map((item, i) => (
                        <div className="col-lg-3 col-md-6 p-2" key={i}>
                            <Card className='service-card d-block' as={Link} to={`/services/${item.slug}`}>
                                <Card.Img as={LazyLoadImage} effect="blur" width={'100%'} variant="top" height={220} src={item.image.url} />
                                <Card.Body>
                                    <Card.Title><span className='text-danger'>{item.title}</span> </Card.Title>
                                    <Card.Text className='small'>{item.description.substring(0, 100)}...</Card.Text>
                                    {/* <div className="d-flex x-between">
                                        <div className="d-flex gap-2 align-items-end">
                                            <div>
                                                <img src={require('../../assets/images/villa.png')} />
                                            </div>
                                            <span className='fw-semibold text-sm'>{item.type}</span>
                                        </div>
                                        <div className="d-flex gap-2 align-items-end">
                                            <div>
                                                <img src={require('../../assets/images/size.png')} />
                                            </div>
                                            <span className='fw-semibold small'>{item.sq_feet} Sq.Ft</span>
                                        </div>
                                        <div className="d-flex gap-2 align-items-end">
                                            <div>
                                                <img src={require('../../assets/images/key.png')} />
                                            </div>
                                            <span className='fw-semibold small'>{item.room_type}BHK</span>
                                        </div>
                                    </div> */}
                                </Card.Body>
                            </Card>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ServiceCards