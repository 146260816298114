import React from 'react'
import { BlogGrid } from '../components'

const Blogs = () => {
    return (
        <div className='container'>
            <BlogGrid />
        </div>
    )
}

export default Blogs