import Aos from 'aos';
import "aos/dist/aos.css";
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import './app.scss';
import { Services } from './components';
import BlogView from './pages/BlogView';
import Blogs from './pages/Blogs';
import Home from './pages/Home';
import ServiceView from './pages/ServiceView';

function App() {
  Aos.init();
  return (
    <BrowserRouter>
      <Routes>
        <Route Component={Layout}>
          <Route index Component={Home} />
          <Route path='/blogs' Component={Blogs} />
          <Route path='/blogs/:slug' Component={BlogView} />
          <Route path='/services' Component={Services} />
          <Route path='/services/:slug/:id' Component={Services} />
          <Route path='/services/:slug' Component={ServiceView} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App