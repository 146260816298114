import React, { useState } from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { FaBars } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { Link, NavLink } from 'react-router-dom';
import ServiceDropDown from '../ServiceDropDown';
import './header.scss';


const Header = () => {
    const isMobile = window.innerWidth < 992
    const [isOpened, setOpened] = useState(false)
    return (
        <header>
            <div className="nav-headline">
                <div className="container">
                    <div className="row y-center text-white py-2 text-lg-start text-center">
                        <div className='col-lg mb-lg-0 mb-3'>WE HAVE MORE THAN <span className='text-danger'>2+</span> YEARS OF EXPERIENCES</div>
                        <div className='col-lg text-lg-end'>
                            <a href="https://www.instagram.com/gnanam_homes_estate/" target='_blank' className='ms-2 btn-sm btn-outline-light btn'><FaInstagram /></a>
                            <a href="https://www.facebook.com/profile.php?id=61557115796140" target='_blank' className='ms-2 btn-sm btn-outline-light btn'><FaFacebook /></a>
                            <a href="https://wa.me/+919940256161" target='_blank' className='ms-2 btn-sm btn-outline-light btn'><FaWhatsapp /></a>
                            {/* <a href="" className='ms-2 btn-sm btn-outline-light btn'><FaYoutube /></a> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {
                    isMobile ?
                        <div className="d-flex gap-3 y-center x-between my-3 sticky-top">
                            <Link to={'/'}>
                                <img src={require('./../../assets/images/logo.png')} width={180} />
                            </Link>
                            <button className='btn btn-outline-primary btn-rounded btn-sm' onClick={() => setOpened(!isOpened)}>
                                {isOpened ? <IoClose size={30} /> : <FaBars size={30} />}
                            </button>
                        </div>
                        :
                        <NavContent hideLogo={false} />
                }
                {
                    isOpened ? <NavContent hideLogo={true} /> : null
                }
            </div>
        </header>
    )
}
const ActionIcons = () => {
    return (
        <div className="col-lg-7 mb-3 mb-lg-0">
            <div className="row y-center">
                <div className="col-4 p-0">
                    <div className="d-flex y-center action-icon">
                        <div>
                            <img src={require('./../../assets/images/location.png')} width={33} />
                        </div>
                        <div className='ms-2'>
                            <span className='action-text'>FIND US</span>
                            <p className='fw-bold m-0'><a href="https://g.co/kgs/PtHpWE4" target='_blank' className='action-link'>
                                Ravi Colony, Parangi Malai, Chennai</a></p>
                        </div>
                    </div>
                </div>
                <div className="col-4 p-0">
                    <div className="d-flex y-center action-icon">
                        <div>
                            <img src={require('./../../assets/images/call.png')} width={33} />
                        </div>
                        <div className='ms-2'>
                            <span className='action-text'>CALL US TODAY</span>
                            <p className='fw-bold m-0'><a href="tel:918270211001" className='action-link'>+91 82702 11001</a></p>
                        </div>
                    </div>
                </div>
                <div className="col-4 p-0">
                    <div className="d-flex y-center action-icon">
                        <div>
                            <img src={require('./../../assets/images/mail.png')} width={33} />
                        </div>
                        <div className='ms-2'>
                            <span className='action-text'>EMAIL US NOW</span>
                            <p className='fw-bold m-0'><a href="mailto:sales@gnanamhomes.com" className='action-link'>sales@gnanamhomes.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const NavContent = ({ hideLogo }) => {
    return (
        <>
            {
                !hideLogo ?
                    <div className="row y-center py-3 x-between text-lg-start text-center">
                        <Link to={'/'} className="col-lg mb-lg-0 mb-3">
                            <img src={require('./../../assets/images/logo.png')} width={180} />
                        </Link>
                        <ActionIcons />
                    </div>
                    : <ActionIcons />
            }
            <nav>
                <div className="row">
                    <div className="col-lg-8">
                        <NavLink className='navbar-link' to={'/'}>Home</NavLink>
                        <a className='navbar-link' href='#about-us'>About</a>
                        <ServiceDropDown />
                        <Dropdown className='d-inline' as={ButtonGroup}>
                            <Button className='navbar-link' as={'a'} to={'/#projects'} style={{ marginTop: -6 }}>
                                Projects
                                <Dropdown.Toggle className='btn-unstyle' split />
                            </Button>
                            <Dropdown.Menu>
                                <Dropdown.Item className='d-block' as={Link} to={`/`}>Upcoming Projects</Dropdown.Item>
                                <Dropdown.Item className='d-block' as={Link} to={`/`}>Ongoing Projects</Dropdown.Item>
                                <Dropdown.Item className='d-block' as={Link} to={`/`}>Completed Projects</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <a className='navbar-link' href='#contact-us'>Joint ventures</a>
                        <NavLink className='navbar-link' to='/blogs'>Blogs</NavLink>
                        <a className='navbar-link' href='#contact-us'>Contact</a>
                    </div>
                    <div className="col">
                        <a className='btn-cta' href='#contact-us'>INQUERY TODAY</a>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header