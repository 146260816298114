import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm';

const Footer = () => {
    return (
        <footer className='text-lg-start text-center' id='contact-us'>
            <div className='bg-primary text-white py-5'>
                <div className="container">
                    <div className="row x-between">
                        <div className="col-xl-3 col-lg-4" data-aos="fade-up">
                            <p className='lead fw-bold'>GNANAM HOMES</p>
                            <p className='fw-light'>Panchatcharam is the Managing Director of Gnanam Engineering and Gnanam Homes and Estate in India Panchatcharam has 20 years of experience, serving in several leadership and customer service roles He was associated with L&T, BGR, Ramky, and ITD, both industrial and residential Property</p>
                            <div className='mt-lg-5'>
                                <div>Support: Consultation [or] enquires</div>
                                <div>Call: <a href="tel:918270211001" className='text-white'>+91 82702 11001</a></div>
                                <div>Mail: <a href="mailto:sales@gnanamhomes.com" className='text-white'>sales@gnanamhomes.com</a></div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 mt-lg-0 mt-4" data-aos="fade-up" data-aos-delay="200">
                            <p className='lead fw-bold'>QUICK LINKS</p>
                            <div className="row">
                                <div className="col-lg-6">
                                    <Link className='fw-light d-block text-white py-1'>Home</Link>
                                    <Link className='fw-light d-block text-white py-1'>About us</Link>
                                    <Link className='fw-light d-block text-white py-1'>Services</Link>
                                    <Link className='fw-light d-block text-white py-1'>Plots</Link>
                                    <Link className='fw-light d-block text-white py-1'>Villas</Link>
                                    <Link className='fw-light d-block text-white py-1'>Apartment</Link>
                                    <Link className='fw-light d-block text-white py-1'>Farm House</Link>
                                </div>
                                <div className="col-lg-6">
                                    <Link className='fw-light d-block text-white py-1'>Project</Link>
                                    <Link className='fw-light d-block text-white py-1'>Upcoming project</Link>
                                    <Link className='fw-light d-block text-white py-1'>Ongoing project</Link>
                                    <Link className='fw-light d-block text-white py-1'>Complete projects</Link>
                                    <a href='#contact-us' className='fw-light d-block text-white py-1'>Contact us</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 mt-lg-0 mt-4" data-aos="fade-up" data-aos-delay="400">
                            <p className='lead fw-bold m-0'>Just say Hello</p>
                            <p className='fw-light'>Let us know more about you</p>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-dark py-3">
                <div className="container">
                    <div className="row y-center text-white py-2 text-lg-start text-center">
                        <div className='col-lg mb-lg-0 mb-3'>
                            Copyright @2024 ejnarstudios | Allrights reserved by gnanamhomes privacy policy | Terms of use | Legal</div>
                        <div className='col-lg-3 text-lg-end'>
                            <a href="https://www.instagram.com/gnanam_homes_estate/" target='_blank' className='ms-2 btn-sm btn-outline-light btn'><FaInstagram /></a>
                            <a href="" className='ms-2 btn-sm btn-outline-light btn'><FaFacebook /></a>
                            <a href="" className='ms-2 btn-sm btn-outline-light btn'><FaLinkedinIn /></a>
                            <a href="" className='ms-2 btn-sm btn-outline-light btn'><FaYoutube /></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer